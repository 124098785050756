<template>
  <div class="m-0 px-3 pt-4 pb-0">
    <back-button />
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Groups</h1>
    </div>
    <div class="mt-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div class="d-flex">
          <base-input class="d-inline-block width-285" label="Search" placeholder="Search by name"
            v-model="request.search" type="search" @input="filterChange" />
        </div>
      </div>
      <el-table row-key="id" class="groups-table" header-row-class-name="thead-light" width="100%"
        :data="response.groups" v-loading="loaders.groups">
        <el-table-column label="Group Admin" min-width="350">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.patient ? scope.row.patient.full_name : 'N/A' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Group Name" min-width="350">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Group User" min-width="350">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.user_ids | getMemberCount }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CREATED" min-width="120">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.created | getCreatedAt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="180">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="View Group" placement="top">
              <router-link :to="{ name: 'admin.groups.show', params: { id: row.id } }">
                <img class="pointer mr-2" src="/img/eye.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Delete Group" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/delete.svg" @click="promptRemove(row)" alt="">
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination class="pagination-no-border float-right" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="pagination.totalPages" @input="changePage" />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal :loader="loaders.removeModal" :modal-text="removeModalText" :removeId="removeId"
        @onRemove="deleteRequest" />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "groups",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton,
  },
  data() {
    return {
      loaders: {
        groups: false,
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      request: {
        search: ''
      },
      response: {
        groups: [],
      },
      pagination: {
        currentPage: 1,
        totalPages: 1,
        perPage: 10
      },
    }
  },
  mounted() {
    this.getGroupsListing()
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getCreatedAt: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    },
    getMemberCount: function (user_ids) {
      return user_ids.split(",").length;
    },
    getSerialNumber: function(currentPage, index) {
      return (currentPage - 1) * (index + 1)
    }
  },
  methods: {
    filterChange() {
      this.pagination.currentPage = 1;
      this.getGroupsListing()
    },
    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.pagination.currentPage = item
      this.getGroupsListing()
    },

    /**
     * Get Groups Listing
     */
    getGroupsListing() {
      let vm = this
      vm.loaders.groups = true
      const payload = {
        page: vm.pagination.currentPage,
        search: vm.request.search,
      }
      vm.$store.dispatch('GroupsModule/_get', payload)
        .then(response => {
          vm.response.groups = response.data.data.data
          vm.pagination.currentPage = response.data.data.current_page
          vm.pagination.totalPages = response.data.data.total
          vm.pagination.perPage = response.data.data.per_page;
          vm.loaders.groups = false
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            vm.loaders.groups = false
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: 'Groups',
              message: message
            })
          }
        })
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Group.'
      this.removeId = row.id
    },

    /**
     * Delete Group(s)
     * @param data
     */
    deleteRequest(id) {
      let vm = this
      vm.loaders.groups = true
      vm.$store.dispatch('GroupsModule/_delete', id)
        .then(response => {
          vm.removeId = []
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: response.data.message
          })
          vm.loaders.groups = false
          vm.getGroupsListing();
        })
        .catch((error) => {
          vm.loaders.groups = false
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Groups',
            message: message
          })
        })
    },
  }
}
</script>

<style scoped></style>
